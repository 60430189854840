import { createAsyncThunk } from "@reduxjs/toolkit";
import { create } from "yup/lib/Reference";
import api from "../../../Service/api";

//Actions for Members Page
export const getAllMembers = createAsyncThunk(
  "getAllMembers",
  async (thunkApi) => {
    const response = await api.get("/adminPanel/getAllMembers");
    return response.data;
  }
);

export const getTransactions = createAsyncThunk("getTransactions", async (data) => {
  const response = await api.post("/adminPanel/getTransactions", data);
  return response.data;
});

export const getMemberDetails = createAsyncThunk(
  "getMemberDetails",
  async (memberId, thunkApi) => {
    const response = await api.get(`/adminPanel/memberDetails/${memberId}`);
    return response.data;
  }
);

// doctor detail admin
// export const getDoctorDetails = createAsyncThunk(
//   "getDoctorDetails",
//   async (memberId,reportId, thunkApi) => {
//     const response = await api.get(`/adminPanel/dentist/details`, {
//       params : {
//         dentistId : memberId,
//         reportId: reportId
//       }
//     });
//     return response.data;
//   }
// );
export const getDoctorDetails = createAsyncThunk(
  "getDoctorDetails",
  async ({ memberId, reportId }, { rejectWithValue }) => {
    try {
      // Build params dynamically based on reportId existence
      const params = {
        dentistId: memberId,
      };

      // Add reportId only if it's provided
      if (reportId) {
        params.reportId = reportId;
      }

      const response = await api.get(`/adminPanel/dentist/details`, { params });
      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


// suspend doctor
export const suspendDentistAccount = createAsyncThunk(
  "suspendDentistAccount",
  async (data, thunkApi) => {
    const response = await api.post(`/adminPanel/user/suspend`, data);
    return response.data;
  }
);

// reset doctor
export const resetUserAccount = createAsyncThunk(
  "resetUserAccount",
  async (data, thunkApi) => {
    const response = await api.post(`/adminPanel/reset-user/account`, data);
    return response.data;
  }
);


//Actions for NewPrescriptions Page
export const createPrescription = createAsyncThunk(
  "createPrescription",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/createPrescription", data);
    return response.data;
  }
);

export const getPrescriptions = createAsyncThunk(
  "getPrescriptions",
  async (id, thunkApi) => {
    const response = await api.get(`/adminPanel/getPrescriptions/${id}`);
    return response.data;
  }
);

// Actions for Blogs Page
export const blogPost = createAsyncThunk(
  "blogPost",
  async (formdata, thunkApi) => {
    const response = await api.post("/adminPanel/blogPost", formdata);
    return response.data;
  }
);

export const getBlogs = createAsyncThunk("getBlogs", async (thunkApi) => {
  const response = await api.get("/adminPanel/getBlogs");
  return response.data;
});

export const deleteBlog = createAsyncThunk(
  "deleteBlog",
  async (blogId, thunkApi) => {
    const response = await api.delete(`/adminPanel/delBlog/${blogId}`);
    return response.data;
  }
);

export const viewBlog = createAsyncThunk(
  "viewBlog",
  async (blogId, thunkApi) => {
    const response = await api.get(`/adminPanel/viewBlog/${blogId}`);
    return response.data;
  }
);

export const getCalendarEvents = createAsyncThunk(
  "getCalendarEvents",
  async (data,thunkApi) => {
    const response = await api.post("/adminPanel/calendarEvents",data);
    return response.data;
  }
);

export const getConsInfo = createAsyncThunk(
  "getConsInfo",
  async (id, thunkApi) => {
    const response = await api.get(`/adminPanel/getConsInfo/${id}`);
    return response.data;
  }
);

export const editBlog = createAsyncThunk(
  "editBlog",
  async (formdata, thunkApi) => {
    const response = await api.post("/adminPanel/editBlog/", formdata);
    return response.data;
  }
);

export const delConsultation = createAsyncThunk(
  "delConsultation",
  async (id, thunkApi) => {
    const response = await api.delete(`/adminPanel/delConsultation/${id}`);
    return response.data;
  }
);

export const sendAvailability = createAsyncThunk(
  "sendAvailability",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/setAvail", data);
    return response.data;
  }
);

export const getAvailability = createAsyncThunk(
  "getAvailability",
  async (data, thunkApi) => {
    const response = await api.get("/adminPanel/getAvail");
    return response.data;
  }
);

export const deleteMember = createAsyncThunk(
  "deleteMember",
  async (id, thunkApi) => {
    const response = await api.delete(`/adminPanel/deleteMember/${id}`);
    return response.data;
  }
);

export const getAdmins = createAsyncThunk("getAdmins", async (thunkApi) => {
  const response = await api.get("/adminPanel/getAdmins");
  return response.data;
});

export const createAdmin = createAsyncThunk(
  "createAdmin",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/createAdmin", data);
    return response.data;
  }
);

export const deleteAdmin = createAsyncThunk(
  "deleteAdmin",
  async (id, thunkApi) => {
    const response = await api.delete(`/adminPanel/deleteAdmin/${id}`);
    return response.data;
  }
);

export const assignDoctor = createAsyncThunk(
  "assignDoctor",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/assignDoctor", data);
    return response.data;
  }
);

export const createPaymentIntent = createAsyncThunk(
  "createPaymentIntent",
  async (data, thunkApi) => {
    const response = await api.post("/pay/create-payment-intent", data);
    return response.data;
  }
);

export const getDashboardData = createAsyncThunk(
  "getDashboardData",
  async (thunkApi) => {
    const res = await api.get("/adminPanel/dashboard");
    return res.data;
  }
);


export const getHelpAndSupportIssue = createAsyncThunk("getHelpAndSupportIssue", async () => {
  const response = await api.get("/adminPanel/getHelpSupport");
  return response.data;
});

export const assignIssueStatus = createAsyncThunk("assignIssueStatus", async (data) => {
  const response = await api.put("/adminPanel/getHelpSupport/status", data);
  return response.data;
});


//******************************* DENTITST ****************************//

export const getDentist = createAsyncThunk("getDentist", async (data,thunkApi) => {
  const response = await api.post("/adminPanel/getDentist",data);
  return response.data;
});

export const createDentist = createAsyncThunk(
  "createDentist",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/createDentist", data);
    return response.data;
  }
);
export const editDentist = createAsyncThunk(
  "editDentist",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/editDentist", data);
    return response.data;
  }
);

export const deleteDentist = createAsyncThunk(
  "deleteDentist",
  async (id, thunkApi) => {
    const response = await api.delete(`/adminPanel/deleteDentist/${id}`);
    return response.data;
  }
);

//******************************* CUSTOMER NEW REQUEST ****************************//

export const getNewRequests = createAsyncThunk("getNewRequests", async (data, thunkApi) => {
  const response = await api.post("/adminPanel/getNewRequests", data);
  return response.data;
});

export const assignNewDoctor = createAsyncThunk(
  "assignNewDoctor",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/assignNewDoctor", data);
    return response.data;
  }
);

export const assignUpdateDoctor = createAsyncThunk(
  "assignUpdateDoctor",
  async (data, thunkApi) => {
    const response = await api.post("/adminPanel/updateDoctor", data);
    return response.data;
  }
);


export const getAllBecomeDoctor = createAsyncThunk("getAllBecomeDoctor", async (data,thunkApi) => {
  const response = await api.get("/adminPanel/getAllBecomeDoctor");
  return response.data;
});

export const dentistApprovalStatus = createAsyncThunk("dentistApprovalStatus", async (data,thunkApi) => {
  const response = await api.post("/adminPanel/createDentistWithStatus", data);
  return response.data;
});