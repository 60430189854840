
import React from "react";

const DentistDetail = ({ fullName, email, age, address, desc, data }) => {


  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="tbl_user_info">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                <h4 className="title_text">Dentist Details</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">Name </label>
                        <div className="input-view-text">{fullName}</div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Age
                        </label>
                        <div className="input-view-text">{age}</div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Email
                        </label>
                        <div className="input-view-text">{email}</div>
                      </div>

                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Phone Number
                        </label>
                        <div className="input-view-text">{data?.phnNumber || "N/A"}</div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">

                          Indemnity Number
                        </label>
                        <div className="input-view-text">{data?.indemnity_number || "N/A"}</div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Indemnity Provider
                        </label>
                        <div className="input-view-text">{data?.indemnity_provider || "N/A"}</div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Highest Qualification
                        </label>
                        <div className="input-view-text">{data?.highest_qualification || "N/A"}</div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Gdc Number
                        </label>
                        <div className="input-view-text">{data?.gdc_no || "N/A"}</div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          Year Of Graduation
                        </label>
                        <div className="input-view-text">{data?.year_of_grad || "N/A"}</div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          University Attended
                        </label>
                        <div className="input-view-text">{data?.uni_attended || "N/A"}</div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">

                <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label htmlFor="" className="view-label-input">
                          DBS Number
                        </label>
                        <div className="input-view-text">{data?.dbs_no || "N/A"}</div>
                      </div>
                    </div>



                  <label htmlFor="" className="view-label-input">
                    Address
                  </label>
                  <div className="form-group mb-3">
                    <div className="input-view-text">

                      {(
                        address?.houseNo +
                        " " +
                        address?.street +
                        " " +
                        address?.secondLine
                      ).replace(/undefined/g, "")}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="input-view-text">{address?.townCity}</div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="input-view-text">{address?.postCode}</div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="" className="view-label-input">
                        Message
                      </label>
                      <div className="form-group mb-3">
                        <div className="input-view-text h-auto message-overf-set-inner">
                          {desc ? desc : "N/A"}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DentistDetail;
