import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import DentistDetail from "./DentistDetail";
import MemberDetailDentistTitle from "./MemberDetailDentistTitle";
import { getDoctorDetails } from "../../../Redux/Actions/admin/adminPanel";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
import moment from "moment";
import { calculateAge, capitalizeFirstLetter } from "../../../utils/staticData";
import ConsultationDentist from "./ConsultationDentist";

export default function DentistMemberComponent() {
  const dispatch = useDispatch();
  const memberId = useParams().id;
  const dentistDetails = useSelector((state) => state.adminPanel.dentistdetail);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportId = queryParams.get('reportId');

  // useEffect(() => {
  //   dispatch(startstopLoading(true));
  //   dispatch(getDoctorDetails({memberId, reportId})).then((res) => {
  //     if (res?.payload?.success == true || res?.payload?.success == false) {
  //       dispatch(startstopLoading(false));
  //     }
  //   });
  // }, [dispatch]);

  useEffect(() => {
    dispatch(startstopLoading(true));

    // Dispatch the action with both memberId and reportId
    if (reportId) {
      dispatch(getDoctorDetails({ memberId, reportId })).then((res) => {
        if (res?.payload?.success == true || res?.payload?.success == false) {
          dispatch(startstopLoading(false));
        }
      });
    } else {
      dispatch(getDoctorDetails({ memberId })).then((res) => {
        if (res?.payload?.success == true || res?.payload?.success == false) {
          dispatch(startstopLoading(false));
        }
      });
    }
  }, [dispatch, memberId, reportId]);

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {dentistDetails?.denstist && (
                <>
                  <MemberDetailDentistTitle
                    firstName={capitalizeFirstLetter(dentistDetails?.denstist?.firstName)}
                    lastName={capitalizeFirstLetter(dentistDetails?.denstist?.lastName)}
                    joinedAt={moment(
                      dentistDetails?.denstist?.createdAt
                    ).format("DD/MM/YYYY")}
                  />
                  <DentistDetail
                    email={dentistDetails?.denstist?.email}
                    fullName={`${capitalizeFirstLetter(dentistDetails?.denstist?.firstName) || ''} ${capitalizeFirstLetter(dentistDetails?.denstist?.lastName) || ''}`}
                    age={ calculateAge(dentistDetails?.denstist?.dob)}
                    address={dentistDetails?.denstist?.address}
                    desc={dentistDetails?.report?.desc}
                    data={dentistDetails?.denstist}
                  />

                  <ConsultationDentist
                    detail={dentistDetails?.denstist}
                    upcomingConsultations={dentistDetails?.upcomingConsultations}
                    pastConsultations={dentistDetails?.pastConsultations}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
